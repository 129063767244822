<template>
  <!-- rgb(241 241 251) #F3FBFF  | EAEFF0 | rgb(13,46,69,.03)-->
  <BasePage>
    <Container>
      <HeroSection class="mb-14" />
      <div class=" mb-14">
        <!-- <v-container> -->
        <Announcements class="" />
        <!-- </v-container> -->
      </div>
      <!--  src="https://www.youtube.com/embed/EiOkEwEm1Uw" -->
      <!-- height="600" -->

      <!-- :src="settingsGetter.video_link" -->
      <!-- src="https://www.youtube.com/embed/5cPQR6-ZVIE" -->
      <iframe
        v-if="settingsGetter"
        :src="settingsGetter.video_link"
        width="100%"
        height="600"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <!-- <div style="height:60vh" class="my-14 ">
        <iframe
          v-if="settingsGetter"
          :src="settingsGetter.video_link"
          width="100%"
          height="100%"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div> -->
      <MostRead class="mb-14" />
    </Container>
  </BasePage>
</template>

<script>
import 'vue-pdf-app/dist/icons/main.css';
import { mapGetters } from 'vuex';
// import { mapActions } from 'vuex';
export default {
  components: {
    MostRead: () => import('./MostRead'),
    HeroSection: () => import('./HeroSection'),
    Announcements: () => import('./Announcements'),
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['settingsGetter']),
  },
};
</script>

<style></style>
